.parallax{
	-ms-background-position-x: center;
	background-position-x: center;
	position: relative;
	overflow: hidden;

	&>*{
		position: relative;
		z-index: 3;
	}

	.parallax-bg-img{
		position: absolute;
		top: 50%;
		left: 50%;
		width: auto;
		height: auto;
		min-width: 100%;
		max-width: none;
		z-index: -1;

		&.mode-2{
			top: 0;
		}
	}
}