/******

CSS Flex (Prefixed)
--------
1. Flex
2. Align Items
3. Justify Content

******/

.display-flex{
	display: -webkit-flex;
	display: flex;
}

.flex-direction(@dir){
	-webkit-flex-direction: @dir;
	flex-direction: @dir;
}

.flex-wrap(@wrap){
	-webkit-flex-wrap: @wrap;
	flex-wrap: @wrap;
}

.align-items(@alignItems){
	-webkit-align-items: @alignItems;
	align-items: @alignItems;
}

.justify-content(@justifyContent){
	-webkit-justify-content: @justifyContent;
	justify-content: @justifyContent;
}

.flex(@flex){
	-webkit-flex: @flex;
	flex: @flex;
}