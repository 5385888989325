/*------------------------------------------------------------------
[Table of contents]

1. Variables
2. Basic
3. Navigation / body>nav
4. Footer / body>footer

-------------------------------------------------------------------*/




/*------------------------------------------------------------------
[1. Variables]
*/

@sansSerif: 'Fira Sans', jf-jinxuan, 'cwTeXHei', sans-serif;
@handwritingFont:  jf-albatron-w6, 'Dancing Script', cursive;
@primaryColor: #E86C6C;
@marginHorizontal: 8rem;
@darkGray: #4A4A4A;
@lightGray: #9B9B9B;
@galleryGutter: 1rem;
@tDuration: .3s;
@midPrimaryColor: hsl(hue(@primaryColor), saturation(@primaryColor), 50%);
@percentageOfContrast: 60%;




/*------------------------------------------------------------------
[2. Basic]
*/

.preloader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255,255,255,.8);
	z-index: 999;
	.display-flex;
	.justify-content(center);
	.align-items(center);
	.transition('opacity @{tDuration} ease');

	&.done{
		opacity: 0;
	}

	&:after{
		position: relative;
		content: '';
		display: block;
		width: 4em;
		height: 4em;
		border-radius: 2em;
		border: 4px solid #ddd;
		border-top-color: @primaryColor;
		animation: spin 1s infinite linear;
	}
}

.animation-chain{
	visibility: hidden;
}

.animated{
	visibility: visible !important;
	animation-duration: .8s;
	.transform(~'translateZ(0)');
}

.bg-primary-color{
	background-color: @primaryColor;
	color: fadeout(#fff, 25%);

	h1, h2, h3, h4, h5, h6{
		color: fadeout(#fff, 50%);
	}

	a:link, a:visited{
		color: lighten(@primaryColor, 65%);
	}
	
	a:hover, a:active{
		color: #fff;
	}
}

.bg-dark-gray{
	background-color: @darkGray;
	color: #fff;

	h1, h2, h3, h4, h5, h6{
		color: #fff;
	}

	a:link, a:visited{
		color: lighten(@darkGray, 30%);
	}
	
	a:hover, a:active{
		color: #fff;
	}
}

.bg-light-gray{
	background-color: lighten(@lightGray, 35%);
	background-image: url('../img/seigaiha.png');
}

.list-reset{
	margin: 0;
	padding: 0;
	list-style: none;
}

.filter(@filter){
	-webkit-filter: @filter;
	-moz-filter: @filter;
	-ms-filter: @filter;
	filter: @filter;
}

.uppercase{
	text-transform: uppercase;
	letter-spacing: 3px;
}

.call-to-action{
	margin: 3em 0 0;
	text-align: center;
	color: @primaryColor;
	font-family: @sansSerif;
	.uppercase;
	font-size: .9em;

	&>p{
		display: inline-block;
		box-shadow: 0 0 0 1px @primaryColor, 0 0 0 3px #fff, 0 0 0 4px @primaryColor;
		padding: .8em 1em;
		border-radius: 10px;
		margin-bottom: 0;

		a:link, a:visited{
			display: block;
			color: @primaryColor;	
		}
		
		a:hover, a:active{
			color: lighten(@primaryColor, 30%);
		}

		span.lnr{
			font-size: 2em;
			vertical-align: middle;
			margin-right: .25em;
		}
	}
}

a:link, a:visited{
	color: @primaryColor;	
	outline: 0;
}

a:hover, a:active{
	color: lighten(@primaryColor, 20%);
}

.btn{
	border-width:0;
	.uppercase;
	font-family: @sansSerif;
	padding: 1em 1.5em;
	border-radius: 0 !important;

	&:focus{
		outline: none;
	}

	&:active, &.active{
		box-shadow: none;
	}

	&.btn-primary{
		background-color: @primaryColor;
		color: #fff;
		.uppercase;
		font-weight: 700;

		&:hover{
			background-color: darken(@primaryColor, 10%);
			color: #fff;
		}
	}
}

ul.social{
	.list-reset;
	margin-bottom: 1em;

	&>li{
		display: inline-block;

		&>a{
			font-size: 1.5em;
			width: 1em;
			display: block;
			vertical-align: middle;
			&:link, &:visited{
				color: rgba(255,255,255,1);
				.transition(~'all @{tDuration} ease');
			}
			
			&:hover, &:active{
				color: rgba(255,255,255,.8);
				color: #fff;
			}
		}

		&+li{
			margin-left: .8rem;
		}
	}
}

@keyframes scroll-down {
	0%{ 
		.transform(~'translate3d(0,0,0)');
		opacity: 1;
	}
	30%{
		opacity: 1;
	}
	100% { 
		.transform(~'translate3d(0,10px,0)');
		opacity: 0;
	}
}

/*
	Loader
	Adapted from: http://www.w3schools.com/howto/howto_css_loader.asp
*/
@keyframes spin {
	0% { .transform(~'rotate(0deg)'); }
	100% { .transform(~'rotate(360deg)'); }
}

/*
	Custom Scrollbar Styling
	Adapted from: http://cssdeck.com/labs/css3-webkit-vertical-scrollbars/
*/
::-webkit-scrollbar-track
{
	background-color: lighten(@lightGray, 25%);
}

::-webkit-scrollbar
{
	width: 9px;
	background-color: lighten(@lightGray, 25%);
}

::-webkit-scrollbar-thumb
{
	background-color: @primaryColor;
}

/* Handling super long words / urls
   Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
*/
.hyphens{
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

iframe{
	border-width: 0;
}

img{
	max-width: 100%;
}

html, body{
	height: 100% !important;
	font-size: 18px;
	line-height: 1.6;
	font-family: @sansSerif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: @darkGray;
}

h1, h2, h3, h4, h5, h6{
	font-family: @sansSerif;
	font-weight: 700;
	margin-top: 1em;
	margin-bottom: .5em;
}

a:hover, a:active, a:focus{
	text-decoration: none;
}

body{




/*------------------------------------------------------------------
[3. Navigation / body>nav]
*/

	&>nav{
		position: relative;
		z-index: 9;
		height: 3rem;
		.transition(~'all @{tDuration} ease');

		.menu-wrapper{
			position: relative;
			min-height: 3rem;
			background-color: #fff;
			box-shadow: 0 3px 3px rgba(0,0,0,.05);
			color: @darkGray;
			padding: 0 @marginHorizontal;
			.transition(~'all @{tDuration} ease');

			a:link, a:visited{
				color: @darkGray;
				display: block;
			}
			
			a:hover, a:active{
				color: @primaryColor;
			}

			ul.menu{
				.list-reset;
				margin: 0 auto;
				text-align: center;
				font-size: .7em;
				font-weight: 700;
				.uppercase;
				letter-spacing: 2px;

				&>li{
					display: inline-block;
					line-height: 3rem;

					&+li{
						margin-left: 3em;
					}

					&.current-menu-item{
						position: relative;
						a:link, a:visited{
							color: @primaryColor;
						}
						&:before{
							position: absolute;
							content: '';
							display: block;
							background-image: url("../img/heart.svg");
							background-size: contain;
							background-repeat: no-repeat;
							left: 50%;
							top: calc(~'1.428em - 12px');
							.transform(~'translateX(-50%)');
							width: 10px;
							height: 10px;
						}
					}
				}
			}
		}

		&.nav-fixed{
			.menu-wrapper{
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 99;
			}
		}

		button.open-menu{
			display: none;
			position: absolute;
			top: .75rem;
			left: 50%;
			width: 1.5rem;
			height: 1.5rem;
			margin-left: -.75rem;
			border-radius: 0;
			text-indent: -9999px;
			border-width: 0;
			padding: 0;
			outline: none;
			cursor: pointer;
			.transition(~'all @{tDuration} ease');
			background-color: transparent;
			overflow: hidden;

			&:before, &:after{
				position: absolute;
				display: block;
				content: '';
				top: 0;
				right: 0;
				width: 100%;
				height: 2px;
				background-color: @lightGray;
				.transition(~'all @{tDuration} ease');
			}

			&:before{
				box-shadow: 10px 11px 0 @lightGray;
			}

			&:after{
				.transform(~'translate3d(0, 22px, 0)');
				width: 75%;
			}
		}

		&.opened{
			.menu-wrapper{
				ul.menu{
					display: block;
				}

				button.open-menu{
					.transform(~'rotate(360deg)');

					&:before{
						.transform(~'translate3d(0, 11px,0) rotate(45deg)');
						box-shadow: 0 -17px 0 transparent;
					}
					&:after{
						width: 100%;
						.transform(~'translate3d(0, 11px,0) rotate(-45deg)');
					}
				}
			}
		}

		&:not(.opened){
			.menu-wrapper{
				overflow: hidden !important;
			}
		}
	}




/*------------------------------------------------------------------
[4. Footer / body>footer]
*/

	&>footer{
		padding: @marginHorizontal/2 @marginHorizontal;
		position: relative;
		background-color: lighten(@lightGray, 35%);
		text-align: center;

		&:after{
			position: absolute;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border: 2em solid transparent;
			z-index: 2;
			bottom: 0;
			right: 0;
			border-bottom-color: #fff;
			border-right-color: #fff;
		}

		&>*{
			position: relative;
			z-index: 2;
			color: #fff;
		}

		.footer-bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.filter(~'contrast(@{percentageOfContrast})');
			background-position: center center;
			background-size: cover;
			z-index: 1;
		}

		.heart{
			position: absolute;
			top: 0;
			left: 50%;
			.transform(~'translateX(-50%) translateY(-50%)');
			svg{
				display: block;
				margin: 0 auto;
				height: 56px;
				use{
					fill: @primaryColor;
				}
			}
		}

		h2{
			font-size: 2em;
			font-family: @handwritingFont;
			font-weight: 400;
			margin-top: .5em;
			margin-bottom: .25em;
		}

		h3{
			font-size: 1em;
			font-size: 1.5em;
			margin: 0 0 1em;
		}

		a.scroll-up{
			display: block;
			font-size: 1.25em;
			position: absolute;
			bottom: 0;
			right: 1.5vmin;
			z-index: 3;

			&:link, &:visited{
				color: @lightGray;
			}
			
			&:hover, &:active{
					
			}
		}
	}
}
