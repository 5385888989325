@charset "UTF-8";

/*------------------------------------------------------------------
[Table of contents]

1. Import
2. Sections / section
3. Separator / .separator
4. Owl Carousel Overwrite / .owl-theme
5. Boxed Layout
6. Responsiveness

-------------------------------------------------------------------*/





/*------------------------------------------------------------------
[1. Import]
*/

@import url('css-flex.less');
@import url('css-trans-ani.less');
@import url('basic.less');
@import url('parallax.less');
@import url(//fonts.googleapis.com/earlyaccess/cwtexfangsong.css);
@import url(//fonts.googleapis.com/earlyaccess/cwtexhei.css);



/*------------------------------------------------------------------
[2. Sections / nav+section, section]
*/

nav+section{
	margin-top: @marginHorizontal/4*3;
}

section{
	margin: @marginHorizontal/2 0;
	padding: @marginHorizontal/4 @marginHorizontal;
	overflow: hidden;
	position: relative;

	&.bg-light-gray{
		margin: @marginHorizontal/4*3 0;
		padding-top: @marginHorizontal/2;
		padding-bottom: @marginHorizontal/2;
	}

	h2{
		text-align: center;
		.uppercase;
		font-size: 1.8em;
		margin: 0;
		padding: 1.1em 0;
		margin-bottom: 1em;
		position: relative;

		&:before, &:after{
			position: absolute;
			content: '';
			display: block;
			background-image: url('../img/decor.svg');
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			width: 100%;
			height: 1em;
			top: 0;
			left: 0;
			.transform(~'rotate(180deg)');
		}

		&:after{
			top: auto;
			bottom: 0;
			.transform(~'rotate(0deg)');
		}
	}
	.section-intro{
		width: 36em;
		max-width: 80%;
		margin: 2em auto 3em;
		text-align: center;
	}

	&.section-hero{
		margin: 0;
		height: 100%;
		min-height: 320px;
		position: relative;
		.display-flex;
		.align-items(center);
		.justify-content(center);
		background-color: lighten(@lightGray, 35%);
		z-index: 1;

		&:after{
			position: absolute;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border: 3em solid transparent;
			z-index: 2;
			bottom: 0;
			left: 0;
			border-bottom-color: #fff;
			border-left-color: #fff;
		}

		.owl-carousel{
			z-index: 1;

			.item{
				position: relative;
				.filter(~'contrast(@{percentageOfContrast})');

				&:before{
					position: absolute;
					content: '';
					display: block;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}

			&.owl-theme{
				.owl-nav.disabled+.owl-dots{
					.flex-direction(column);
					position: absolute;
					top: 50%;
					margin-top: 0;
					.transform(~'translateY(-50%)');
					right: 2em;
				}
				.owl-dots .owl-dot span{
					margin: 7px;
					box-shadow: none;
				}
			}
		}

		.announcement-wrapper{
			text-align: center;
			position: relative;
			z-index: 2;

			.announcement{
				position: relative;
				margin-bottom: 0;
				font-size: 1.25em;
				margin-bottom: 2em;

				.heart{
					margin-bottom: 1em;
					svg{
						display: block;
						margin: 0 auto;
						height: 35px;
						height: 5.25vmin;
						use{
							fill: @primaryColor;
						}
					}
				}

				.decor{
					svg{
						display: block;
						margin: 0 auto;
						height: 7.65vmin;
						width: 42.15vmin;
						use{
							fill: #fff;
						}
					}
				}

				.decor-inverse{
					&>svg{
						.transform(~'scaleY(-1)');
					}
				}

				h1, h2{
					color: #fff;
				}

				h2{
					margin: 0;
					padding: 0;
					font-size: 2em;
					font-size: 6.75vmin;
					font-family: @handwritingFont;
					font-weight: 400;
					text-transform: none;
					letter-spacing: 0;

					&:before, &:after{
						position: absolute;
						content: '';
						display: none;
					}
				}

				h1{
					margin: 0;
					font-size: 2em;
					font-size: 6.75vmin;
					.display-flex;
					.justify-content(space-between);

					span.and{
						font-weight: 400;
					}
				}

				.date{
					margin: 0;
					overflow: hidden;
					font-size: 1em;
					font-size: 3.375vmin;

					span{
						position: relative;
						display: inline-block;
						color: #fff;

						&:before, &:after{
							position: absolute;
							content: '';
							display: block;
							top: 50%;
							height: 1px;
							width: 500px;
							background-color: #fff;
						}

						&:before{
							right: 100%;
							margin-right: 1em;
						}

						&:after{
							left: 100%;
							margin-left: 1em;
						}
					}
				}
			}

			.cta{
				visibility: hidden;
			}
		}

		.scroll-down{
			position: absolute;
			z-index: 3;
			bottom: -.5555em;
			left: 1.67em;
			width: 25px;
			height: 40px;
			border-radius: 12.5px;
			border: 2px solid @lightGray;
			cursor: pointer;
			.transform(~'translateX(-50%) translateY(-50%)');

			&:before{
				position: absolute;
				content: '';
				display: block;
				width: 3px;
				height: 8px;
				background-color: @lightGray;
				top: 8px;
				left: 9px;
				animation: 'scroll-down' 1s ease infinite;
			}
		}
	}

	&.section-couple{
		margin-top: 0;
		padding-top: @marginHorizontal;

		.flowers{
			overflow: hidden;
			&:before, &:after{
				position: absolute;
				content: '';
				display: block;
				width: 16rem;
				width: 43.2vmin;
				height: 16rem;
				height: 43.2vmin;
				background-image: url('../img/flowers.svg');
				background-position: center center;
				background-size: cover;
				opacity: .1;
				top: -@marginHorizontal*.6;
			}

			&:before{
				left: -8rem;
				left: -21.6vmin;
			}

			&:after{
				right: -8rem;
				right: -21.6vmin;
			}
		}

		.bride-and-groom{
			.display-flex;
			.justify-content(space-between);
			position: relative;

			&:before{
				position: absolute;
				content: '&';
				display: block;
				font-family: @handwritingFont;
				margin-top: 17.6%;
				top: 0;
				left: 50%;
				.transform(~'translateX(-50%) translateY(-50%)');
				font-size: 4em;
				color: @lightGray;
				visibility: visible;
			}


			&>.bride, &>.groom{
				width: 44%;
				text-align: center;

				.profile-pic{
					width: 80%;
					margin: 0 auto;
					margin-bottom: 1em;
					overflow: hidden;
					position: relative;

					&:after{
						position: absolute;
						content: '';
						display: block;
						top: .5em;
						left: .5em;
						right: .5em;
						bottom: .5em;
						border: 1px solid #fff;
						visibility: visible;
					}
				}

				h3{
					font-size: 1.55em;
					margin: 0;
					line-height: 1.25;
				}

				h4{
					font-size: 1.25em;
					font-weight: 400;
					margin: 0;
					margin-bottom: 1em;
				}

				p{
					color: @lightGray;
					max-width: 20em;
					margin: 0 auto;
				}

				ul.social{
					margin-top: 1em;
					a:link, a:visited{
						color: @lightGray;
					}
					
					a:hover, a:active{
						color: @primaryColor;
					}
				}
			}
		}
	}

	&.section-story{
		ul.timeline{
			.list-reset;
			position: relative;

			&:before{
				position: absolute;
				content: '';
				display: block;
				width: 1px;
				left: 50%;
				top: 0;
				bottom: 0;
				background-color: fadeout(@lightGray, 50%);
			}

			&>li{
				.display-flex;
				.justify-content(space-between);
				.align-items(center);
				position: relative;

				&:hover{
					.meta .timeline-thumbnail{
						.filter(~'contrast(100%)');
					}
				}

				&:nth-child(2n){
					.flex-direction(row-reverse);

					.meta{
						padding-right: 0;
						padding-left: 4em;
						padding-left: 6vw;

						.timeline-thumbnail{
							margin-right: 0;
							margin-left: auto;
							position: relative;
						}

						.date{
							left: 2em;
							left: 6vw;
							right: auto;
						}
					}
					.desc{
						padding-left: 0;
						padding-right: 4em;
						padding-right: 6vw;
					}
				}

				&+li{
					margin-top: 1em;
				}

				svg.heart{
					position: absolute;
					top: 50%;
					left: 50%;
					.transform(~'translateX(-50%) translateY(-50%)');
					z-index: 2;

					path{
						fill: @primaryColor;
					}
				}

				.meta, .desc{
					width: 50%;
				}

				.meta{
					position: relative;
					padding-right: 4em;
					padding-right: 6vw;

					&:before{
						position: absolute;
						content: '';
						display: block;
						height: 1px;
						width: 100%;
						background-color: fadeout(@lightGray, 50%);
						top: 50%;
						left: 0;
					}

					.timeline-thumbnail{
						width: 225px;
						height: 225px;
						width: 18vw;
						height: 18vw;
						border-radius: 50%;
						overflow: hidden;
						position: relative;
						z-index: 1;
						background-position: center center;
						background-size: cover;
						.filter(~'contrast(@{percentageOfContrast})');
						.transition(~'all ease @{tDuration}');
					}

					.date{
						font-size: 2em;
						font-size: 3vw;
						font-family: @handwritingFont;
						position: absolute;
						top: 50%;
						right: 2em;
						right: 6vw;
						.transform(~'translateY(-100%)');
						color: @lightGray;
					}

				}

				.desc{
					overflow: hidden;
					padding-left: 4em;
					padding-left: 6vw;
				}
			}
		}
	}

	&.section-events{
		ul.event-list{
			.list-reset;
			width: 52em;
			max-width: 100%;
			margin: 0 auto;

			&>li{
				.display-flex;
				.align-items(stretch);

				&+li{
					margin-top: 3em;
					padding-top: 3em;
					box-shadow: 0 -1px 0 fadeout(@lightGray, 70%);
				}

				.event-thumbnail{
					width: 13em;
					margin-right: 3em;
					background-position: center center;
					background-size: cover;
				}

				.event-details{
					width: calc(~'100% - 16em');
					overflow: hidden;

					h3{
						font-family: @handwritingFont;
						font-size: 1.67em;
						margin: 0;
					}

					ul.event-info{
						.list-reset;
						margin: 1em 0;
						font-size: .9em;
						font-weight: 700;
						line-height: 1.8;

						&>li{
							span{
								display: inline-block;
								color: @primaryColor;
								width: 2em;
							}
						}
					}

					.desc{
						color: @lightGray;
						max-width: 100%;
					}

					.cta{
						a.btn+a.btn{
							margin-left: 1em;
						}
					}
				}
			}
		}
	}

	&.section-rsvp{
		form{
			text-align: center;
			&>.form-wrapper{
				.display-flex;
				.align-items(stretch);
				margin-bottom: 1em;

				.left{
					width: 35%;
					input{
						width: 100%;
						padding: .5em;
						border: 0 solid fadeout(@lightGray, 50%);
						outline: none;
					    background-color: transparent;
					    border-bottom-width: 1px;

						&+input{
							margin-top: 1em;
						}
					}
				}

				.right{
					width: calc(~'65% - 1em');
					margin-left: 1em;
					.display-flex;
					.align-items(stretch);
					.flex-direction(row);

					ul.events{
						.list-reset;
						.display-flex;
						.justify-content(space-between);
						.flex-direction(column);
						width: 100%;

						li{
							background-size: cover;
							background-position: center center;
							cursor: pointer;
							position: relative;
							.display-flex;
							.flex-direction(column);
							.justify-content(center);

							&>*{
								position: relative;
								z-index: 2;
							}

							&:before{
								position: absolute;
								content: '';
								display: block;
								background-color: #000;
								opacity: .3;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								.transition(~'opacity @{tDuration} ease');
								z-index: 1;
							}

							&:hover:before{
								opacity: 0;
							}

							&:after{
								position: absolute;
								display: block;
								content: '\f00c';
								font-size: 1em;
								font-family: 'FontAwesome';
								color: #fff;
								width: 1.5em;
								height: 1.5em;
								line-height: 1.5em;
								top: -.35em;
								right: -.75em;
								z-index: 2;
								background-color: @primaryColor;
								border-radius: 50%;
								box-shadow: 0 0 0 3px #fff;
								.transform(~'scale(0)');
								.transition(~'all @{tDuration} ease');
							}

							&.selected:after{
								.transform(~'scale(1)');
							}

							p{
								margin: 0;
								color: #fff;
								font-weight: 700;
								.uppercase;
								text-shadow: 0 0 10px rgba(0,0,0,.5);
								padding: 1em;
							}

							&+li{
								margin-top: 1em;
							}
						}
					}

					select{
						display: none;
						width: 100%;
						height: 100%;
						border: 1px solid fadeout(@lightGray, 50%);
						outline-color: @primaryColor;

						option{
							padding: .5em;
						}
					}
				}
			}
		}
		#message{
			text-align: center;
			margin-top: 1em;
			p{
				margin: 0;
			}
		}
	}

	&.section-people{
		h3{
			font-family: @handwritingFont;
			text-align: center;
			font-size: 1.67em;
			margin: 1.5em 0 .75em;

			&:first-child{
				margin-top: 0;
			}
		}
		.container-fluid{
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.row{
			overflow: hidden;
			margin-left: -1rem;
			margin-right: -1rem;

			.col-sm-3{
				padding-left: 1rem;
				padding-right: 1rem;

				&:hover{
					.profile-pic{
						.filter(~'contrast(100%)');
					}
				}
				.profile-pic{
					padding-top: 100%;
					background-position: center center;
					background-size: cover;
					.filter(~'contrast(@{percentageOfContrast})');
					.transition(~'all @{tDuration} ease');
				}
				h4{
					text-align: center;
					font-weight: 400;
					margin: .5em 0 0;
					font-size: 1em;
				}
			}
		}
	}

	&.section-gallery{
		.gallery-overlay{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 102;
			display: none;
			.align-items(center);
			.justify-content(center);
			opacity: 0;
			.transition(~'opacity @{tDuration} ease');
			background-color: fade(#000, 70%);

			&:before{
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				content: '';
				border: 4px solid rgba(255,255,255,.1);
				border-top: 4px solid @primaryColor;
				border-radius: 50%;
				width: 60px;
				height: 60px;
				margin-top: -30px;
				margin-left: -30px;
				animation: spin 1s linear infinite;
			}

			&.opened{
				opacity: 1;
			}

			&.loaded{
				&:before{
					display: none;
				}
			}

			.gallery-modal{
				width: 80%;
				.transform(~'scale(0)');
				opacity: 0;
				.transition(~'all @{tDuration} ease');
				background-color: #fff;
				box-shadow: 5px 5px 16px rgba(0,0,0,.2);

				ul.modal-nav{
					position: absolute;
					bottom: 100%;
					right: 0;
					.list-reset;
					background-color: @primaryColor;
					color: #fff;
					.display-flex;
					box-shadow: 5px 5px 16px rgba(0,0,0,.2);

					&:before{
						display: block;
						content: '';
						position: absolute;
						right: 100%;
						top: 0;
						.transform-origin(~'right top');
						.transform(~'rotate(-45deg)');
						background-color: @primaryColor;
						color: #fff;
						width: 60px;
						height: 60px;
						box-shadow: 5px 5px 16px rgba(0,0,0,.2);
						z-index: 1;
					}

					&:after{
						position: absolute;
						content: '';
						display: block;
						top: 100%;
						height: 50px;
						width: 170px;
						right: 0;
						background-color: #fff;
						z-index: 1;
					}

					&>li{
						font-size: 1.25em;
						text-align: center;
						width: 0;
						height: 40px;
						line-height: 40px;
						background-color: @primaryColor;
						color: #fff;
						z-index: 2;
						.transition(~'all @{tDuration} ease');

						&:hover{
							background-color: darken(@primaryColor, 10%);
						}

						&.enabled{
							width: 40px;
						}

						a:link, a:visited{
							color: #fff;
							display: block;
							&:after{
								display: none;
							}
						}
						
						a:hover, a:active{
							text-decoration: none;
						}
					}
				}

				.overflow-wrapper{
					padding: 2.5rem;
					position: relative;
					z-index: 1;
				}
			
				.modal-main{
					.display-flex;
					.flex-direction(row-reverse);
					padding: 0;
					.align-items(center);
			
					.left{
						width: 30%;
						padding-right: @marginHorizontal/2;
			
						ul{
							.list-reset;
			
							&>li{
								margin-bottom: 2em;
								&:last-child{
									margin-bottom: 0;
								}
								&.name-cat{
									h3{
										color: @primaryColor;
										font-size: 1.4em;
										line-height: 1.25;
										margin: 0;
										margin-bottom: .25em;
				
										&+p{
											color: @darkGray;
											font-size: .8em;
											.uppercase;
										}
									}
								}
								&.desc{
									font-size: .9em;
								}
							}
						}
					}
			
					.right{
						width: 70%;
						line-height: 0;

						.wide-screen{
							height: 0;
							width: 100%;
							padding-top: 56.25%;
							position: relative;

							&>iframe, &>a{
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
							}

							&>a:link, &:visited{
								display: block;

								&:before{
									display: block;
									position: absolute;
									content: '';
									background-image: url('../img/youtube-play.svg');
									background-size: contain;
									background-repeat: no-repeat;
									background-position: center center;
									width: 80px;
									height: 80px;
									top: 50%;
									left: 50%;
									margin-left: -40px;
									margin-top: -40px;
									opacity: .5;
									.transition(~'opacity @{tDuration} ease');
								}

								&:hover, &:active{
									&:before{
										opacity: .8;
									}
								}

								&:after{
									display: none;
								}
							}
						}
					}
			
					.left, .right{
						z-index: 1;
						position: relative;
					}
				}
			
				&.opened{
					.transform(~'scale(1)');
					opacity: 1;
				}
			}
		}

		header{
			.display-flex;
			.justify-content(space-between);
			.align-items(center);
			margin-bottom: 2.4rem;

			h2{
				margin: 0;
			}

			ul.gallery-cats{
				.list-reset;
				font-family: @sansSerif;
				.uppercase;
				font-size: .7em;
				font-weight: 700;
				letter-spacing: 2px;
				padding-top: 1em;
				text-align: center;

				&>li{
					display: inline-block;
					&+li{
						margin-left: 2em;
					}

					&>a{
						display: block;
						padding: .5em 0;
					}
				}
			}
		}

		.gallery-grid{
			margin: -@galleryGutter;

			&>.gallery-grid-item{
				width: 33.33%;
				padding: @galleryGutter;
				overflow: hidden;
				position: relative;

				img.gallery-video-icon{
					width: 72px;
					height: 72px;
					position: absolute;
					top: 50%;
					left: 50%;
					opacity: .5;
					.transform(~'translateX(-50%) translateY(-50%)');
					.transition(~'opacity ease @{tDuration}');
					z-index: 2;
				}

				img:not(.gallery-video-icon){
					width: 100%;
					height: auto;
					.transition(~'all @{tDuration} ease');
					.filter(~'contrast(@{percentageOfContrast})');
				}

				&:hover{
					cursor: pointer;

					img.gallery-video-icon{
						opacity: .8;
					}
					
					img:not(.gallery-video-icon){
						.filter(~'contrast(100%)');
					}
				}

				.gallery-hidden{
					display: none;
				}
			}
		}
	}

	&.section-gifts{
		.retailer-list{
			.row+.row{
				margin-top: 2em;
			}
			.row{
				.retailer-thumbnail{
					border: 1px solid fadeout(@lightGray, 50%);
					height: 100px;
					text-align: center;
					line-height: calc(~'100px - 2em');
					padding: 1em 2em;

					&>img{
						max-height: 100%;
					}

					&+h4{
						text-align: center;
						font-weight: 400;
						margin: .5em 0 0;
						font-size: 1em;
					}
				}
			}
		}
	}

	&+footer{
		margin-top: @marginHorizontal/4*3;
	}
}





/*------------------------------------------------------------------
[3. Separator / separator]
*/

.separator{
	margin: @marginHorizontal/4*3 0;
	padding: @marginHorizontal/4 0;

	&:before, &:after{
		position: absolute;
		content: '';
		display: block;
		width: 0;
		height: 0;
		border: 1.6em solid transparent;
	}

	&:before{
		top: 0;
		right: 0;
		border-top-color: #fff;
		border-right-color: #fff;
	}

	&:after{
		bottom: 0;
		left: 0;
		border-bottom-color: #fff;
		border-left-color: #fff;
	}

	.flowers{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&:before, &:after{
			position: absolute;
			content: '';
			display: block;
			width: 14.4rem;
			width: 38.9vmin;
			height: 14.4rem;
			height: 38.9vmin;
			background-image: url('../img/flowers-white.svg');
			background-position: center center;
			background-size: cover;
		}

		&:before{
			left: -9rem;
			left: -24.3vmin;
			top: -@marginHorizontal*.18;
		}

		&:after{
			right: -9rem;
			right: -24.3vmin;
			bottom: -@marginHorizontal*.18;
		}
	}

	.separator-carousel{
		.owl-carousel{
			overflow: hidden;
			position: relative;

			.item{
				position: relative;
				display: block;
				height: 0;
				padding-top: 100%;
				background-size: cover;
				background-position: center center;
				margin: 0 auto;
				.filter(~'contrast(@{percentageOfContrast})');
				.transition(~'all ease @{tDuration}');

				&:hover{
					.filter(~'contrast(100%)');
				}
			}
		}
	}

	&.parallax{
		padding: @marginHorizontal/2 @marginHorizontal;
		color: #fff;
		text-align: center;
		.display-flex;
		.flex-direction(column);
		.justify-content(center);
		.align-items(center);
		min-height: 20em;

		.parallax-bg-img{
			.filter(~'contrast(@{percentageOfContrast})');
		}

		h2{
			font-size: 2em;
			font-size: 3vw;
			margin: 0;
		}

		.decor{
			font-size: 2em;
			font-size: 3vw;
			display: inline-block;
			position: relative;
			margin: .75em 0 0;
			margin: 1.125vw 0 0;

			&:before, &:after{
				position: absolute;
				content: '';
				display: block;
				top: 50%;
				width: 100px;
				height: 2px;
				margin-top: -3px;
				background-color: #fff;
			}

			&:before{
				right: 100%;
				margin-right: .5rem;
			}

			&:after{
				left: 100%;
				margin-left: .5rem;
			}
		}

		.countdown-area{
			font-size: 1.5em;
			font-size: 2.25vw;
			line-height: 4.8em;
			line-height: 7.2vw;

			ul{
				.list-reset;
				.display-flex;

				&>li{
					span.digits{
						font-size: 3em;
						font-size: 6.75vw;
						margin-right: .1em;
					}
					span.unit{
						margin-right: .5em;
					}
				}
			}
		}
	}
}





/*------------------------------------------------------------------
[4. Owl Carousel Overwrite / .owl-theme]
*/

@import url('owl-overwrite.less');






/*------------------------------------------------------------------
[5. Boxed Layout]
*/

/*@import url('boxed.less');*/






/*------------------------------------------------------------------
[6. Responsiveness]
*/

@import url('responsive.less');
