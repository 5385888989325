/*------------------------------------------------------------------
[Owl Carousel Overwrite]
*/

.owl-theme .owl-dots .owl-dot span{
	border-radius: 0;
	background-color: fadeout(#fff, 20%);
	box-shadow: inset 0 0 0 1px @primaryColor;
}

.owl-theme .owl-dots .owl-dot{
	display: block;
}

.owl-theme .owl-nav.disabled+.owl-dots{
	.display-flex;
	.justify-content(center);
	margin-top: 1em;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
	background: @primaryColor;
	box-shadow: none;
}

.owl-theme .owl-nav .owl-prev, .owl-theme .owl-nav .owl-next{
	position: absolute;
	top: 50%;
	margin: 0;
    margin: 0;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background-color: rgba(0,0,0,.1);
    overflow: hidden;
    text-indent: -9999px;
    background-size: 2em auto !important;
    background-repeat: no-repeat !important;
}

.owl-theme .owl-nav{
	margin-top: 0;
}

.owl-theme .owl-nav .owl-prev{
	left: 0;
    transform: translateY(-50%) translateX(-50%);
    background-image: url('../img/arrow-left.svg') !important;
    background-position: 3.4em center !important;
}

.owl-theme .owl-nav .owl-next{
	right: 0;
    transform: translateY(-50%) translateX(50%);
    background-image: url('../img/arrow-right.svg') !important;
    background-position: .6em center !important;
}

.owl-theme .owl-nav [class*=owl-]:hover{
    background-color: rgba(0,0,0,.15);
}

.owl-carousel.owl-full-width{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.owl-stage-outer, .owl-stage, .owl-item{
		width: 100%;
		height: 100%;
	}

	.item{
		width: 100%;
		height: 100%;
		background-position: center center;
		background-size: cover;
	}
}